import React, { useContext,useEffect } from 'react';
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import AdbIcon from '@mui/icons-material/Adb';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import { NavLink, Outlet, Link } from 'react-router-dom';
import { routes } from '../../utils/global.config';
import { Web3ProviderContext } from '../../blockchain/Web3ModalContext';

const drawerWidth = 240;
const navItems = [
  { name: 'Sticker Book', path: routes.STICKER_BOOK },
  { name: 'Administrator', path: 'admin' },
  { name: 'Submissions', path: routes.SUBMISSIONS },
  { name: 'Rewards', path: routes.REWARDS },
];
const settings = ['Profile', 'Account', 'Dashboard', 'Logout'];

function DrawerAppBar(props) {
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const { connect, handleLogin,isConnected ,disconnect} = useContext(Web3ProviderContext);

  useEffect(() => {
  
  }, [])
  

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };
  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const connectionHandler = async () => {
    if(isConnected){
      await disconnect()
    }else {
      await handleLogin();
    }
    // const {provied,address,signer} = await connect()
  };

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center' }}>
      <Typography variant="h6" sx={{ my: 2 }}>
        MUI
      </Typography>
      <Divider />
      <List>
        {navItems.map((item) => (
          <ListItem key={item.name} disablePadding>
            <ListItemButton sx={{ textAlign: 'center' }}>
              <ListItemText primary={item.name} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );

  const container = window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{ display: 'flex' }}>
{/* rgb(44, 43, 59) */}
      <AppBar component="nav" sx={{ backgroundColor: 'transparent', borderRadius: '0px 0px 20px 20px', paddingTop: '8px', paddingBottom: '8px' }}>
        <Toolbar>
          <IconButton color="inherit" aria-label="open drawer" edge="start" onClick={handleDrawerToggle} sx={{ mr: 2, display: { sm: 'none' } }}>
            <MenuIcon />
          </IconButton>
          <AdbIcon sx={{ display: { xs: 'none', md: 'flex' }, mr: 1 }} />

          <Typography
            variant="h6"
            component="a"
            href="/"
            noWrap
            sx={{
              flexGrow: 1,
              display: { xs: 'none', sm: 'block' },
              fontFamily: 'Rubik',
              fontWeight: 700,
              fontSize: '30px',
              letterSpacing: '.3rem',
              color: 'inherit',
              textDecoration: 'none',
            }}>
            LOGO
          </Typography>

          <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
            {navItems.map((item,index) => (
              <Link
                style={{
                  color: '#ffffff',
                  fontFamily: 'Rubik',
                  fontWeight: 700,
                  fontSize: '16px',
                  letterSpacing: '.1rem',
                  padding: '20px 15px',
                  textDecoration: 'none',
                }}
                to={item.path}
                key={index}>
                {item.name}
              </Link>
              // <Button
              //   key={item}
              //   sx={{ color: '#ffffff', fontFamily: 'Rubik', fontWeight: 700, fontSize: '16px', letterSpacing: '.1rem', padding: '20px 15px' }}>
              //   {item}
              // </Button>
            ))}
            <Button
              onClick={connectionHandler}
              sx={{
                cursor: 'pointer',
                fontSize: '16px',
                minWidth: '64px',
                padding: '15px 30px',
                background:`${!isConnected ? 'linear-gradient(90deg, rgb(39, 164, 255), rgb(51, 84, 255) 28%, rgb(116, 51, 255) 50%, rgb(246, 74, 187) 79%, rgb(255, 124, 51))':'rgb(100, 100, 120)' } `,
                color: 'rgb(255, 255, 255)',
                fontWeight: '600',
                fontFamily: 'Rubik',
                borderRadius: '50px',
                textTransform: 'uppercase',
              }}>
              {isConnected ? "Disconnect" : "Connect"} 
            </Button>
            {/* <Tooltip title="Open settings">
              <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                <Avatar alt="Remy Sharp" src="/static/images/avatar/2.jpg" />
              </IconButton>
            </Tooltip>
            <Menu
              sx={{ mt: '45px' }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}>
              {settings.map((setting) => (
                <MenuItem key={setting} onClick={handleCloseUserMenu}>
                  <Typography textAlign="center">{setting}</Typography>
                </MenuItem>
              ))}
            </Menu> */}
          </Box>
        </Toolbar>
      </AppBar>
      <Box component="nav">
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': {
              boxSizing: 'border-box',
              width: drawerWidth,
            },
          }}>
          {drawer}
        </Drawer>
      </Box>
      <Box component="main" sx={{ p: 0 }}>
        <Toolbar />
      </Box>
    </Box>
  );
}

DrawerAppBar.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export default DrawerAppBar;
