import * as React from 'react';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import ListSubheader from '@mui/material/ListSubheader';
import IconButton from '@mui/material/IconButton';
import InfoIcon from '@mui/icons-material/Info'
import BookCover from '../assets/images/stickerBookCover.png';
import { useNavigate } from 'react-router-dom';
import { routes } from '../utils/global.config';
import { StringUtils } from '../utils/stringUtils';


export default function TitlebarImageList({stickerBooks,activateBook,onClick}) {
  console.log("stickerbooks",stickerBooks)
  // ()=> item.active ? activateBook(item.name,false) :activateBook(item.name,true)
  const navigate = useNavigate();

    
  return (
    <ImageList cols={6} row={1} sx={{ width: "100%",height:'auto'}}>
      {/* <ImageListItem key="Subheader" >
        <ListSubheader component="div" sx={{background:'transparent',textAlign:'center',fontFamily:'Rubik',fontSize:'30px',fontWeight:600}}>Gallery</ListSubheader>
      </ImageListItem> */}
      {stickerBooks.map((item,index) => (
        <ImageListItem key={index} sx={{cursor:'pointer'}}
        // onClick={()=> item.active ? activateBook(item.name,false) :activateBook(item.name,true)}>
        onClick={() => navigate(StringUtils.replaceStringParams(routes.SB_DETAILS, { bookName: item.name }))}>
          <img
            src={`${BookCover}?w=248&fit=crop&auto=format`}
            // srcSet={`${'https://images.unsplash.com/photo-1551782450-a2132b4ba21d'}?w=248&fit=crop&auto=format&dpr=2 2x`}
            alt={item.name}
            loading="lazy"
          />
          <ImageListItemBar
            sx={{
                '& .MuiImageListItemBar-title': {
                    fontSize:'20px',
                    fontFamily:'Rubik'

                  },
                '& .MuiImageListItemBar-subtitle': {
                    fontSize:'16px',
                    fontFamily:'Rubik',
                    color:`${item.active ?  '#5cb85c' : '#d9534f'}`

                  },
            }}
            title={`${item.name} (${item.stickerCount})`}
            subtitle={item.active ? "Active":"Not active"}
            // actionIcon={
            //   <IconButton sx={{ color: 'rgba(255, 255, 255, 0.54)' }} aria-label={`info about ${item.title}`}>
            //     <InfoIcon />
            //   </IconButton>
            // }
          />
        </ImageListItem>
      ))}
    </ImageList>
  );
}

const itemData = [
  {
    img: 'https://images.unsplash.com/photo-1551963831-b3b1ca40c98e',
    title: 'Breakfast',
    author: '@bkristastucchio',
  },
  {
    img: 'https://images.unsplash.com/photo-1551782450-a2132b4ba21d',
    title: 'Burger',
    author: '@rollelflex_graphy726',
  },
  {
    img: 'https://images.unsplash.com/photo-1522770179533-24471fcdba45',
    title: 'Camera',
    author: '@helloimnik',
  },
  {
    img: 'https://images.unsplash.com/photo-1444418776041-9c7e33cc5a9c',
    title: 'Coffee',
    author: '@nolanissac',
  },
  {
    img: 'https://images.unsplash.com/photo-1533827432537-70133748f5c8',
    title: 'Hats',
    author: '@hjrc33',
  },
  {
    img: 'https://images.unsplash.com/photo-1558642452-9d2a7deb7f62',
    title: 'Honey',
    author: '@arwinneil',
  },
  {
    img: 'https://images.unsplash.com/photo-1516802273409-68526ee1bdd6',
    title: 'Basketball',
    author: '@tjdragotta',
  },
  {
    img: 'https://images.unsplash.com/photo-1518756131217-31eb79b20e8f',
    title: 'Fern',
    author: '@katie_wasserman',
  },
  {
    img: 'https://images.unsplash.com/photo-1597645587822-e99fa5d45d25',
    title: 'Mushrooms',
    author: '@silverdalex',
  },
  {
    img: 'https://images.unsplash.com/photo-1567306301408-9b74779a11af',
    title: 'Tomato basil',
    author: '@shelleypauls',
  },
  {
    img: 'https://images.unsplash.com/photo-1471357674240-e1a485acb3e1',
    title: 'Sea star',
    author: '@peterlaster',
  },
  {
    img: 'https://images.unsplash.com/photo-1589118949245-7d38baf380d6',
    title: 'Bike',
    author: '@southside_customs',
  },
];
