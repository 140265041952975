import React, { useContext, useEffect, useState } from 'react';
import HeroSection from '../components/HeroSection';
import {
  FormControl,
  InputLabel,
  Input,
  FormHelperText,
  Grid,
  Box,
  Card,
  CardContent,
  TextField,
  Typography,
  Button,
  Switch,
  Checkbox
} from '@mui/material';
import { Web3ProviderContext } from '../blockchain/Web3ModalContext';
import { abis, addresses, makeContract } from '../blockchain/useContracts';
import CustomTable from '../components/CustomTable';
import AddSticker from '../components/AddSticker';
import AddMultipleSticker from '../components/AddMultipleSticker';
import DisabledBooks from '../components/BookGalary';
import { getTransformBookData } from '../utils/transformObjects';
import CustomBtn from '../components/CustomBtn';
import { Loading } from '../components/Loading';
import FormControlLabel from '@mui/material/FormControlLabel';
import { styled } from '@mui/material/styles';
import {toast,ToastContainer} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { routes } from '../utils/global.config';
import { StringUtils } from '../utils/stringUtils';
import { useNavigate } from 'react-router-dom';



const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(16px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#65C466',
        opacity: 1,
        border: 0,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#33cf4d',
      border: '6px solid #fff',
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color:
        theme.palette.mode === 'light'
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 22,
    height: 22,
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
  },
}));


const StickerBook = () => {
  const { connect } = useContext(Web3ProviderContext);
  const [traits, setTraits] = useState([]);
  const [bookName, setBookName] = useState('');
  const [stickerBooks, setStickerBooks] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isChecked, setIsChecked] = useState(true);
  const [maxRedemption, setMaxRedemption] = useState('');
  const [isClearBadge, setIsClearBadge] = useState(false);

  const navigate = useNavigate();



  useEffect(() => {
    getData();

    // return () => {
    //   second
    // }
  }, []);
  const getData = async () => {
    try {
      setIsLoading(true);
      await getContracts();
      await getBooks();
      setIsLoading(false)

    } catch (error) {
      console.log(error);
      setIsLoading(false)
    }



  }

  const createOnlyBook = async () => {
    debugger
    try {
      const { signer } = await connect();
      // let bookID = await sb.stickerbooks(bookName)
      if (bookName === '') toast.info("Write sticker bookname");
      if (maxRedemption==='') toast.info("Write max redumption");
      if (parseInt(maxRedemption)===0) toast.info("Write max redumption greater then 0");
      if(bookName != ''&& maxRedemption!='' && parseInt(maxRedemption) >0){
        setIsLoading(true)
       let traitToClear = isClearBadge ===true ? traits.find((t)=>t.name==="BADGE").traitId : 0
        const sb = makeContract(addresses.stickerBook, abis.stickerBook, signer);
        await sb.newStickerBook(bookName,parseInt(maxRedemption),isClearBadge,traitToClear);
        setBookName('');
        setIsChecked(false)
        setIsLoading(false)
      }
      
      await getData();
    } catch (error) {
      setIsLoading(false);
      console.log(error);
      // setBookName('');
    }

  };

  const activateBook = async (name, status) => {
    try {
      setIsLoading(true)
      const { signer } = await connect();
      const sb = makeContract(addresses.stickerBook, abis.stickerBook, signer);
      let tx = await sb.activate(name, status);
      await tx.wait();
      await getData();

    } catch (error) {
      setIsLoading(false);

    }

  };

  const getContracts = async () => {
    const addressZero = '0x0000000000000000000000000000000000000000';
    let tempTrait = [];
    const { signer } = await connect();
    const reg = makeContract(addresses.registry, abis.registry, signer);
    const traitCount = await reg.traitCount();

    for (let i = 0; i < traitCount; i++) {
      let obj = {};
      //every thisTrait will be an array
      let thisTrait = await reg.traits(i);
      console.log("thisTrait=>", thisTrait)
      let traitType = thisTrait['implementer'] == addressZero ? 'BOOL' : 'NUMERIC';

      obj['traitId'] = i;
      obj['name'] = thisTrait['name'];
      obj['implementer'] = thisTrait['implementer'];
      obj['traitType'] = traitType;
      obj['start'] = thisTrait['start'];
      obj['end'] = thisTrait['end'];

      setTraits(tempTrait);
      tempTrait.push(obj);

      if (traitType == 'NUMERIC') {
        // let imp = await IMP(thisTrait.implementer)
        // let numData = await imp.getValue(tokenID)
        // console.log("Value : ",numData)
      } else {
        // let b = await tregs.hasTrait(j,tokenID)
        // console.log("State : ",b)
      }
    }
  };
  const getBooks = async () => {
    const { signer } = await connect();
    let books = [];
    const sb = makeContract(addresses.stickerBook, abis.stickerBook, signer);
    let lastBook = await sb.nextBook();
    console.log(lastBook)
    lastBook = parseInt(lastBook);
    for (let i = 1; i <= lastBook; i++) {
      let bData = await sb.stickerBookData(i);
      // if (bData['active'] === false) {
      let love = getTransformBookData(bData);
      books.push(love);
      console.log("love=>", books)
      // }
    }
    setStickerBooks(books)
  };

  // const checkTraits = async (implementer) => {
  //   const { signer } = await connect();
  //   const imple = makeContract(addresses.registry, abis.registry, signer);
  //   for (j = 0; j < 15; j++) {
  //     im = await image.getValue(j + 1);
  //     bv = await background.getValue(j + 1);
  //   }
  // };
  const createHexToken = async () => {
    const { signer } = await connect();
    const cards = makeContract(addresses.cards, abis.cards, signer);

  }
  const toggleHandler = (val) => {
    setIsChecked(val)
    console.log(val)
  }
  const clearBadgeHandler =(isChecked)=>{
    setIsClearBadge(isChecked)
  }

  return (
    <Box>
      <ToastContainer/>
      {isLoading && <Loading />}
      <HeroSection
        headingOne="Galaxis Stickerbook"
        headingTwo="create and get reward"
        description="Neque porro quisquam est qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit..."
      />

      <CustomTable traits={traits} />
      <DisabledBooks stickerBooks={stickerBooks} activateBook={activateBook} />

      {/* input field to create sticker book */}
      {/* <Grid container justifyContent='center' alignItems="center" direction='row'>

      </Grid> */}
      <FormControlLabel
        control={<IOSSwitch sx={{ m: 1 }} />}
        label="Create full sticker book"
        sx={{ color: '#CACCB5', fontFamily: 'Rubik' }}
        onChange={(e) => toggleHandler(e.target.checked)}
        checked={isChecked}
      />

      {/* <Box sx={{background:'#ffffff'}}> */}
      {!isChecked && <Card
        sx={{
          backgroundColor: '#CACCB5',
          padding: '20px 30px 0px 30px',
          borderRadius: '10px',
          marginBottom: '20px',
        }}>
        <CardContent>
          <Grid container spacing={3} justifyContent="center">
            <Grid item md={7}>
              <Typography
                sx={{ color: '#282824', fontSize: '18px', fontWeight: 500, fontFamily: 'Rubik' }}>
                New sticker book{' '}
              </Typography>
              <TextField
                sx={{ backgroundColor: 'white', borderRadius: '10px' }}
                placeholder="Name"
                variant="outlined"
                fullWidth
                value={bookName}
                onChange={(e) => setBookName(e.target.value)}
              />
            </Grid>
            <Grid item md={3}>
              <Typography
                sx={{ color: '#282824', fontSize: '18px', fontWeight: 500, fontFamily: 'Rubik' }}>
                Max Redemptions
              </Typography>
              <TextField
                sx={{ backgroundColor: 'white', borderRadius: '10px' }}
                placeholder="maxRedemption"
                variant="outlined"
                fullWidth
                type="number"
                value={maxRedemption}
                onChange={(e) => setMaxRedemption(e.target.value)}
              />
            </Grid>
            <Grid item md={2}>
              <Typography sx={{ color: '#282824', fontSize: '18px', fontWeight: 500, fontFamily: 'Rubik' }}>
              Clear Badge
              </Typography>
              <Checkbox size="large" checked={isClearBadge} onChange={(e) => clearBadgeHandler(e.target.checked)}/>
            </Grid>


            {/* <Grid item md={12}>
              <Typography sx={{ color: '#ffffff', fontSize: '18px', fontWeight: 500, fontFamily: 'Rubik' }}>Trait registry</Typography>
              <TextField sx={{backgroundColor: 'white',borderRadius:'10px'}} placeholder='Address of NFT collection' variant='outlined' fullWidth/>
            </Grid>
            <Grid item md={12}>
              <Typography sx={{ color: '#ffffff', fontSize: '18px', fontWeight: 500, fontFamily: 'Rubik' }}>No of NFT's</Typography>
              <TextField sx={{backgroundColor: 'white',borderRadius:'10px'}} placeholder='Address of NFT collection' variant='outlined' fullWidth/>
            </Grid> */}
            <Grid item md={12} sx={{ textAlign: 'center' }}>
              <CustomBtn onClick={createOnlyBook} text='Create Book' />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      }

      {/* addSticker to the sticker book */}


      {!isChecked && <AddSticker traits={traits} getBooks={getBooks} />}
      {isChecked && <AddMultipleSticker traits={traits} getBooks={getBooks} />}

      <Button onClick={createHexToken}>Create Hex token</Button>

      {/* </Box> */}
    </Box>
  );
};

export default StickerBook;
