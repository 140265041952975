import { getRealWorldAltValues,getRealWorldAltValuesSingleArry } from "./helper";
export const getTransformBookData = (data = {}) => {
  const active = data['active'];
  const maxRedemptions = data['maxRedemptions'];
  const name = data['name'];
  const numberRedeemed = data['numberRedeemed'];
  const stickerBookId = parseInt(data['stickerBookId']);
  const stickerCount = data['stickerCount'] ? parseInt(data['stickerCount']) : data['stickers'].length;
  const uri = data['uri'];
  const stickers = data['stickers'] ? data['stickers']:[]
  return { name, stickerBookId, active, stickerCount, uri,maxRedemptions,numberRedeemed,stickers };
};
export const getTransformBookStickers = (sticker = {},i) => {
  const name = sticker['name'];
  const index = i;
  const traitID = sticker['traitID'];
  const alternative_values = parseInt(sticker['alternative_values']);
  const realWorldAltValues = getRealWorldAltValues(sticker) || "Something wrong"
  const image= sticker['image'] || ''
  return { index,name, traitID, alternative_values, image, realWorldAltValues  };
};
export const getTransformedBookSingleArray = (sticker = {},i) => {
  const name = sticker['name'];
  const index = i;
  const traitID = sticker['traitID'];
  const alternative_values = parseInt(sticker['alternative_values']);
  const {alternativeIds,titles} = getRealWorldAltValuesSingleArry(sticker) || "Something wrong"
  const image= sticker['image'] || ''
  return { index,name, traitID, alternative_values, image, realWorldAltValues:alternativeIds,titles  };
};

// export const getTransformedStoreDataProjectViewProp = (storeData = {}) => {
//     return {
//       signature: 'test',
//       name: storeData.projectName || '',
//       tags: [storeData.projectTags],
//       shortDescriptionTitle: storeData.projectType || '',
//       artImages: (storeData.projectImages || []).map((image) => image.value),
//       shortDescription: storeData.projectShortDesc || '',
//       // aboutProject: { description: storeData.projectAbout || ''},
//       about: storeData.projectAbout || '',
//       processOfCreation: storeData.projectProcessOfCreation || '',
//       processOfCreationImage: storeData.projectProcessOfCreationImage || '',
//       tiers: (storeData.tiers || []).map((tier, i) => ({
//         id: +i,
//         number: +(tier.number || 0),
//         price: +(tier.price || 0),
//         description: tier.description,
//       })),
//     };
//   };
