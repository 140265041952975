// lib
import React, { useState, useEffect, useContext, Fragment } from 'react';
import { Grid, Card, CardContent, TextField, Typography, Checkbox, IconButton } from '@mui/material';
import { Web3ProviderContext } from '../blockchain/Web3ModalContext';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
//constants
import { abis, addresses, makeContract } from '../blockchain/useContracts';
import { cardColor, cardType, cardSuite, cardValue } from '../data/cards/cardTraits'

//Components
import DropDown from './DropDown';
import TagSelector from './TagSelector';
import CustomBtn from "./CustomBtn"
import { Loading } from './Loading';

const AddSticker = ({ traits }) => {
  const { connect } = useContext(Web3ProviderContext);
  const [bookName, setBookName] = useState('');
  const [traitData, setTraitData] = useState({ traitID: '', alternative_values: [] });
  const [traitsArr, setTraitsArr] = useState([traitData]);
  const [criteria, setCriteria] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => { }, [traitsArr, criteria]);

  const inputHandler = (evt, index) => {
    const name = evt.target.name;
    const val = evt.target.value;
    const updatedTr = traitsArr.map((tr, i) => (index == i ? { ...tr, [name]: [val] } : tr));
    // setTraitData((pre) => ({ ...pre, [name]: [val] }));
    setTraitsArr(updatedTr);
    console.log('traitsArr', traitsArr);
  };

  const filterAltVal = (traitName, index) => {
    switch (traitName) {
      case 'CARD NUMBER':
        setCriteria(cardValue);
        break;
      case 'Suit':
        setCriteria(cardSuite);
        break;
      case 'Court Card':
        setCriteria(cardType);
        break;
      case 'RED':
        setCriteria(cardColor);
        break;
      default:
        setCriteria([])
    }
    // if(traitName==='CARD NUMBER'){
    //   setCriteria(cardValue);
    // }else if (traitName==='Suit'){
    //   setCriteria(cardSuite);
    // }else {
    //   setCriteria([])
    // }

  }

  const bookNameHandler = (evt) => {
    setBookName(evt.target.value);
  };

  const add = async () => {
    try {
      setIsLoading(true)
      const { signer } = await connect();
      const sb = makeContract(addresses.stickerBook, abis.stickerBook, signer);
      if (traitsArr?.length > 1) {
        alert('multiple critaria');
        console.log('=>', traitsArr);
        let tx = await sb.addSticker(bookName, traitsArr, { gasLimit: 1000000 });
        await tx.wait();
      } else {
        alert('single critaria');
        // await sb.addSticker(bookName, [traitsArr]);

        const obj = traitsArr[0];
        // const traitID = obj['traitID'];
        // const alternative_values = obj['alternative_values'];
        // await sb.addSticker("book1", [{traitID,alternative_values}]);
        console.log('=>', traitsArr);

        let tx = await sb.addSticker(bookName, [traitsArr[0]], { gasLimit: 1000000 });
        await tx.wait()
      }
      setTraitsArr([traitData])
      setIsLoading(false);

    } catch (error) {
      console.log(error);
      setTraitsArr([traitData])
      setIsLoading(false)
    }

  };

  const viewSticker = async () => {
    const { signer } = await connect();
    const sb = makeContract(addresses.stickerBook, abis.stickerBook, signer);
    const stickerPosition = 0;
    const bookId = await sb.stickerbooks('book1');
    // console.log("bookId => ",bookId)
    // console.log("bookId ethers.utils.formatEther(num) => ",bigToDecimal(bookId))

    const bookData = await sb.stickerBookData(bookId);
    const stickerCount = bookData['stickerCount'];
    console.log('bookData => ', bookData, parseInt(stickerCount));

    for (let j = 0; j < stickerCount; j++) {
      let sticker = await sb.stickers(bookId, j, stickerPosition);
      console.log('sticker =>', j, sticker);
    }
  };

  const addRow = () => {
    setTraitsArr([...traitsArr, traitData]);
  };
  const remove = (index) => {
    const filteredTr = [...traitsArr];
    filteredTr.splice(index, 1); //remove one item from that index
    console.log('filteredTr', filteredTr);
    setTraitsArr(filteredTr);
  };

  const tagHandler = ( trait, isCkecked) => {
    let temp = traitsArr;
    console.log("tr =>", trait)

    if (isCkecked === false || isCkecked === true && isCkecked !== null) {
      if (isCkecked) {
        temp = temp.map((tr) =>
          tr.traitID == trait.traitID ? { ...tr, alternative_values: [1] } : tr,
        );

      } else if (!isCkecked) {
        temp = temp.map((tr) =>
          tr.traitID == trait.traitID ? { ...tr, alternative_values: [0] } : tr,
        );
      }
    }
    else {
      console.log('check tagHandler fun')
      // criteria.forEach((element) => alternative_val.push(element.id));
      // const updatedTr = traitsArr[index].map((tr, i) => ({ ...tr, alternative_values: alternative_val }));
      // temp[index] = updatedTr;

    }
    setTraitsArr(temp);
  };

  return (
    <>
      {isLoading && <Loading />}
      <Card
        sx={{
          backgroundColor: '#CACCB5',
          padding: '20px 30px 0px 30px',
          borderRadius: '10px',
        }}>
        <CardContent>
          <Grid container spacing={3} justifyContent="center" alignItems="center">
            <Grid item md={12}>
              <Typography
                sx={{ color: '#282824', fontSize: '18px', fontWeight: 500, fontFamily: 'Rubik' }}>
                Sticker book name
              </Typography>
              <TextField
                sx={{ backgroundColor: 'white', borderRadius: '10px' }}
                placeholder="use trait id from the above table"
                variant="outlined"
                fullWidth
                onChange={bookNameHandler}
                name="bookName"
                value={bookName}
              />
            </Grid>
            {traitsArr?.map((tr, index) => (
              <Fragment key={index}>
                <Grid item md={5}>
                  <Typography
                    sx={{
                      color: '#282824',
                      fontSize: '18px',
                      fontWeight: 500,
                      fontFamily: 'Rubik',
                    }}>
                    Select Trait
                  </Typography>
                  <DropDown
                    traits={traits}
                    setTraitsArr={setTraitsArr}
                    traitsArr={traitsArr}
                    rowIndex={index}
                    filterAltVal={filterAltVal}
                  />
                </Grid>

                <Grid item md={5}>
                  <Typography
                    sx={{ color: '#282824', fontSize: '18px', fontWeight: 500, fontFamily: 'Rubik' }}>
                    Alternative values
                  </Typography>
                  {tr.traitID != 2 && tr.traitID != 3 && (
                    <TagSelector setTraitsArr={setTraitsArr} traitsArr={traitsArr} rowIndex={index} tagOptions={criteria} />
                  )}
                  {(tr.traitID === 2 || tr.traitID === 3) && (
                    <Checkbox
                      size="large"
                      onChange={(e) => tagHandler( tr, e.target.checked)}
                    />
                  )}
                  {/* <TextField
                    sx={{ backgroundColor: 'white', borderRadius: '10px' }}
                    placeholder="Address of NFT collection"
                    variant="outlined"
                    fullWidth
                    onChange={(e) => inputHandler(e, index)}
                    name="alternative_values"
                  /> */}
                </Grid>
                <Grid item md={2}>
                  {traitsArr?.length > 1 && (
                    <IconButton
                      sx={{ marginTop: '20px', textAlign: 'center' }}
                      onClick={() => remove(index)}>
                      <DeleteForeverOutlinedIcon fontSize="large" sx={{ color: '#282824' }} />
                    </IconButton>
                  )}
                </Grid>
              </Fragment>
            ))}

            <Grid item md={12} sx={{ textAlign: 'center' }}>
              <CustomBtn text='Add Row' onClick={addRow} />
              <CustomBtn text='Add stickers' onClick={add} />
              <CustomBtn text='View Stickers' onClick={viewSticker} />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </>
  );
};
export default AddSticker;
