import React, { useEffect } from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';

export default function LimitTags({ setTraitsArr, traitsArr, rowIndex, tagOptions }) {
  
  const tagHandler = (criteriaArr) => {
    let alternative_val = [];
    criteriaArr.forEach((element) => {
      alternative_val.push(element.id);
    });
    const updatedTr = traitsArr.map((tr, i) =>
      rowIndex == i ? { ...tr, alternative_values: alternative_val } : tr,
    );
    setTraitsArr(updatedTr);
  };

  return (
    <Autocomplete
      multiple
      limitTags={2}
      id="multiple-limit-tags"
      options={tagOptions}
      getOptionLabel={(option) => option.title}
      defaultValue={[]}
      onChange={(e, newVal) => tagHandler(newVal)}
      renderInput={(params) => <TextField {...params} placeholder="search available tags" />}
      sx={{ width: '100%', background: '#ffffff', borderRadius: '4px' }}
    />
  );
}
