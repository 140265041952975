// lib imports
import React, { createContext, useState, useEffect, useCallback, useRef, useContext } from 'react';
// import WalletConnectProvider from "@walletconnect/web3-provider";
import { ethers } from 'ethers';
import Web3Modal from 'web3modal';
//constants
import { routes } from '../utils/global.config';
import { useAuthContext } from '../context/auth';
// import { providerOptions } from './providerOptions';
import { toast, ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

import { CustomToaster as renderToast } from '../components/Toaster/CustomToaster';

export const Web3ProviderContext = createContext();

const web3Modal = new Web3Modal({
  theme: {
    background: 'rgb(39, 49, 56)',
    main: 'rgb(199, 199, 199)',
    secondary: 'rgb(136, 136, 136)',
    border: 'rgba(195, 195, 195, 0.14)',
    hover: 'rgb(16, 26, 32)',
  },
  network: 'mumbai',
  cacheProvider: true,
  providerOptions:{}
  
});
export const useWeb3ModalContext = () => useContext(Web3ProviderContext);

export function Web3Provider({ children }) {
  const [isConnected, setIsConnected] = useState(false);
  const providerRef = useRef({});
  //   const { isOnCorrectNetwork, loggedIn } = useSelector((state) => state.user);
  //   const dispatch = useDispatch();

  //===========================Connect function =================//
  // const connect = useCallback(
  //   async function () {
  //     if (isConnected && providerRef.current) {
  //       const provider = providerRef.current;
  //       const signer = provider.getSigner();
  //       const address = ethers.utils.getAddress(await signer.getAddress());
  //       return { provider, signer, address };
  //     } else {
  //       const _provider = await web3Modal.connect();
  //       const provider = new ethers.providers.Web3Provider(_provider);
  //       const signer = provider.getSigner();
  //       const _address = await signer.getAddress();
  //       const address = ethers.utils.getAddress(_address);
  //       const _network = await provider.getNetwork();
  //       providerRef.current = provider;
  //       setIsConnected(true);
  //       return { provider, signer, address };
  //     }
  //   },
  //   [isConnected],
  // );

  const connect = async () => {
    const _provider = await web3Modal.connect();
    const provider = new ethers.providers.Web3Provider(_provider);
    console.log("_provider =>",provider)
    const signer = provider.getSigner();
    const _address = await signer.getAddress();
    const address = ethers.utils.getAddress(_address);
    const network = await provider.getNetwork();
    if (network.chainId != parseInt(process.env.REACT_APP_CHAIN_ID)){
      toast.warn(`Please connect to ${process.env.REACT_APP_NETWORK} network`)
    } else {
      return { provider, signer, address, network };
    }
    // providerRef.current = provider;
    // setIsConnected(true);
    
  };

  const disconnect = useCallback(
    async function () {
      await web3Modal.clearCachedProvider();
      if (providerRef.current?.disconnect && typeof providerRef.current.disconnect === 'function') {
        await providerRef.current.disconnect();
      }
      setIsConnected(false);
    },
    [isConnected],
  );

  const handleLogin = async () => {
    const { provider, signer, address,network } = await connect();
    // let tx = 
    await signer.signMessage('Please sign the transaction to login');
    // await tx.wait();
    providerRef.current = provider;
    setIsConnected(true);

    return { provider, signer, address,network };
    const login = async (userExist, provider, signer, address) => {
      // login logic
      //   if (!isOnCorrectNetwork) {
      //     navigate(`${routes.POLYGON_CONNECT}`);
      //   } else if (!userExist) {
      //     navigate(`${routes.SIGNUP}`);
      //   }
    };

    // if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) && !window.ethereum) {
    //   window.location.href = `https://metamask.app.link/dapp/${"demo.minty.art"}`;
    //   return;
    // }
  };

  const handleCheckConnect = async () => {
    const { provider } = await connect();
    providerRef.current = provider;
    setIsConnected(true);
  };

  useEffect(() => {
    if (web3Modal.cachedProvider) {
      handleCheckConnect();
    }
  }, []);

  useEffect(() => {
    if (providerRef.current?.provider) {
      const handleAccountsChanged = (accounts) => {
        const acc = accounts[0]
        disconnect();
        console.log('accountsChanged', acc);
        toast.info(`${"Account changed please connect again"}`)
        // renderToast();
      };

      const handleChainChanged = (chain) => {
        // window.location.reload();
        chain =  parseInt(chain);
        if (chain===parseInt(process.env.REACT_APP_CHAIN_ID)){
          toast.success("Supported Chain");
        }else {
          toast.warn("Unsupported Chain")
        }
        console.log('chainChanged', chain);
        // disconnect();
      };


      const handleDisconnect = (error) => {
        disconnect();
        console.log('disconnect', error);
      };

      providerRef.current.provider.on('accountsChanged', handleAccountsChanged);
      providerRef.current.provider.on('chainChanged', handleChainChanged);
      providerRef.current.provider.on('disconnect', handleDisconnect);

      return () => {
        if (providerRef.current.provider.removeListener) {
          providerRef.current.provider.removeListener('accountsChanged', handleAccountsChanged);
          providerRef.current.provider.removeListener('chainChanged', handleChainChanged);
          providerRef.current.provider.removeListener('disconnect', handleDisconnect);
        }
      };
    }
  }, [providerRef.current, disconnect]);

  

  return (
    <>
    <Web3ProviderContext.Provider
      value={{
        isConnected,
        provider: providerRef.current,
        providerRef: providerRef,
        setIsConnected,
        connect,
        disconnect,
        handleLogin,
      }}>
      {children}
    </Web3ProviderContext.Provider>
    <ToastContainer/>
    </>
  );
}


