import React from 'react';
import { Button } from '@mui/material';

const CustomBtn = ({ text, style = {}, onClick,disabled=false }) => {
  return (
    <Button
      onClick={onClick}
      style={style}
      disabled={disabled}
      sx={{
        color: '#CACCB5',
        backgroundColor: '#F56105',
        fontFamily: 'Rubik',
        fontSize: '16px',
        padding: '15px 30px',
        margin: '10px',
        fontWeight: '600',
        borderRadius: '10px',
        textTransform: 'uppercase',
        '&:hover':{background:'#282824'}
      }}>
      {text}
    </Button>
  );
};

export default CustomBtn;
