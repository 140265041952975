import React from 'react';
import { Typography, Box, Grid,IconButton, Button } from '@mui/material';
import DashboardIcon from '@mui/icons-material/Dashboard';
import ArticleIcon from '@mui/icons-material/Article';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import SettingsIcon from '@mui/icons-material/Settings';
import BookmarkAddedIcon from '@mui/icons-material/BookmarkAdded';

const Rewards = () => {
  return (
    <Box>
      <Typography sx={{ fontFamily: 'Rubik', color: '#F56105', fontSize: '24px', fontWeight: 500,margin:'20px 0px' }}>
        Dashboard
      </Typography>
      <Grid container direction="row" justifyContent="flex-start" alignItems="center" spacing={2}>
      <Grid item md={4} sx={{background:'#CACCB5',padding:'40px',textAlign:'center',borderRadius:2}}>
        <IconButton size='large'><BookmarkAddedIcon/></IconButton>
        <Typography>0.0689 ETH</Typography>
        <Typography>Address</Typography>
        <Button sx={{color:'#CACCB5', backgroundColor:'#F56105'}}>FUnd Deploy</Button>
      </Grid>
      </Grid>
    </Box>
  );
};

export default Rewards;
