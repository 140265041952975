// lib
import React, { useState, useContext, useEffect } from 'react';
// import Typo from '../components/Typo';
import {
  Typography,
  Box,
  Grid,
  Button,
  ImageList,
  ImageListItem,
  ImageListItemBar,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { Web3ProviderContext } from '../blockchain/Web3ModalContext';
//utils
import { routes } from '../utils/global.config';
import { abis, addresses, makeContract } from '../blockchain/useContracts';
import { getTransformBookData } from '../utils/transformObjects';
import { StringUtils } from '../utils/stringUtils';
// components
import HeroSection from '../components/HeroSection';
import BookGalary from '../components/BookGalary';
import CustomBtn from '../components/CustomBtn';
import ClipLoader from "react-spinners/RiseLoader";
import BookCover from "../assets/images/stickerBookCover.png";


function Home() {
  const navigate = useNavigate();
  const { connect } = useContext(Web3ProviderContext);
  const [stickerBooks, setStickerBooks] = useState([]);
  const [loadingBooks, setLoadingBooks] = useState(false);

  useEffect(() => {
    getBooks();
  }, []);

  const getBooks = async () => {
    setLoadingBooks(true);

    const { signer } = await connect();
    let books = [];
    const sb = makeContract(addresses.stickerBook, abis.stickerBook, signer);
    let lastBook = await sb.nextBook();
    lastBook = parseInt(lastBook);
    for (let i = 1; i <= lastBook; i++) {
      let bData = await sb.stickerBookData(i);
      if (bData['active'] === true) {
        let love = getTransformBookData(bData);
        console.log("=>",love)
        books.push(love);
      }
    }
    setStickerBooks(books);
    // setLoadingBooks(false);

  };

  return (
    <>
      {/* section two */}
      <HeroSection
        headingOne="Meet the next-gen"
        headingTwo="Sticker book dApp."
        description="A project by GRD || galaxies Company"
      />

      {/* section two */}
      <Grid
        container
        direction="row"
        justifyContent="space-around"
        sx={{
          width: '100%',
          padding: '30px 20px',
          // position: 'relative',
          borderRadius: '20px',
          background: '#CACCB5',
        }}>
        <Grid item md={6}>
          <Typography
            sx={{
              color: '#282824',
              textAlign: 'left',
              width: '70%',
              fontSize: '36px',
              lineHeight: '42px',
              fontWeight: '700',
              fontFamily: 'Rubik',
            }}>
            Start your journey to the Sticker book of GRD platform and grow your Portfolio.
          </Typography>
        </Grid>

        <Grid item md={6}>
          <Box
            sx={{
              display: 'flex',
              direction: 'row',
              justifyContent: 'center',
              alignItems: 'center',
              background: '#F56105',
              fontFamily: 'Rubik',
              borderRadius: '20px',
              padding: 2,
              margin: '20px 10px 10px 20px',
              maxWidth: '80%',
            }}>
            <Button
              onClick={() => navigate(routes.STICKER_BOOK)}
              sx={{ color: '#ffffff', fontSize: '18px', fontFamily: 'Rubik' }}>
              Create Sticker Book
            </Button>
          </Box>
          <Box
            sx={{
              display: 'flex',
              direction: 'row',
              justifyContent: 'center',
              alignItems: 'center',
              background: '#F56105',
              fontFamily: 'Rubik',
              borderRadius: '20px',
              padding: 2,
              margin: '20px 10px 10px 20px',
              maxWidth: '80%',
            }}>
            <Button sx={{ color: '#ffffff', fontSize: '18px', fontFamily: 'Rubik' }}>
              Create Reward Contract
            </Button>
          </Box>
        </Grid>
      </Grid>

      {/* StickerBook section */}

      <Typography
        sx={{
          textAlign: 'center',
          fontFamily: 'Rubik',
          fontSize: '48px',
          fontWeight: '700',
          margin: '40px 0px',
          color: '#CACCB5',
        }}>
        Sticker Book List
      </Typography>
      {/* <Box
        sx={{
          height: '45rem',
          background:'linear-gradient(40deg, rgb(39, 164, 255), rgb(51, 84, 255) 28%, rgb(116, 51, 255) 50%, rgb(246, 74, 187) 79%, rgb(255, 124, 51))',
          width: '70%',
          margin: 'auto',
          borderRadius: '20px',
        }}></Box> */}

      {/*========================================================================== */}

      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        spacing={1}
        sx={{
          //   height: '45rem',
          height: 'auto',
          // background:'linear-gradient(40deg, rgb(39, 164, 255), rgb(51, 84, 255) 28%, rgb(116, 51, 255) 50%, rgb(246, 74, 187) 79%, rgb(255, 124, 51))',
          background: '#CACCB5',
          width: '100%',
          margin: 'auto',
          borderRadius: '20px',
          padding: '20px',
        }}>


        {
          // <BookGalary stickerBooks={stickerBooks} activateBook={true} onCardClick={()=>navigate(StringUtils.replaceStringParams(routes.BOOK,{bookname:'book1'})) }/>
            // Array.from(Array(24)).map((_, index) => (
            stickerBooks ? stickerBooks.map((item, index) => (
              <Grid item md={3} key={index}>
                <ImageListItem sx={{ cursor: 'pointer' }} onClick={() => navigate(StringUtils.replaceStringParams(routes.BOOK, { bookName: item.name }))}>
                  <img
                    src={`${BookCover}?w=248&fit=crop&auto=format`}
                    // srcSet={`${'https://images.unsplash.com/photo-1551782450-a2132b4ba21d'}?w=248&fit=crop&auto=format&dpr=2 2x`}
                    alt={item.name}
                    loading="lazy"
                  />
                  <ImageListItemBar
                    sx={{
                      '& .MuiImageListItemBar-title': {
                        fontSize: '20px',
                        fontFamily: 'Rubik',
                      },
                      '& .MuiImageListItemBar-subtitle': {
                        fontSize: '16px',
                        fontFamily: 'Rubik',
                        color: `${item.active ? '#5cb85c' : '#d9534f'}`,
                      },
                    }}
                    title={`${item.name} (${item.stickerCount})`}
                    subtitle={item.active ? 'Active' : 'Not active'}
                  // actionIcon={
                  //   <IconButton sx={{ color: 'rgba(255, 255, 255, 0.54)' }} aria-label={`info about ${item.title}`}>
                  //     <InfoIcon />
                  //   </IconButton>
                  // }
                  />
                </ImageListItem>

                {/* <Box sx={{ padding: '50px', backgroundColor: 'rgb(35, 35, 47)', borderRadius: '20px', boxShadow: 'rgb(0 0 0 / 8%) 0px 2px 6px 0px' }}>
                <Typography sx={{ textAlign: 'center', color: '#ffffff', fontSize: '20px', fontWeight: 500, fontFamily: 'Rubik' }}>
                  Name or IMG
                </Typography>
                <Typography sx={{ textAlign: 'center', color: '#ffffff', fontSize: '14px', fontWeight: 300, fontFamily: 'Rubik' }}>
                  Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy
                </Typography>
              </Box> */}


              </Grid>
            )): <ClipLoader color={"#152D2E"} loading={loadingBooks} size={40} />
        }
      </Grid>
    </>
  );
}

export default Home;
