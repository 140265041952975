import * as React from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

export default function SelectLabels({ traits, setTraitsArr, rowIndex ,traitsArr,filterAltVal}) {
  // console.log(traits)
  const [trait, setTrait] = React.useState('');

  const dropDownHandler = (event) => {
    let name = event.target.value;
    setTrait(name);
    let tId = traits.filter((trait)=> trait.name === name);
    if (tId?.length) tId = tId[0]['traitId'];
    const updatedTr = traitsArr.map((tr, i) => (rowIndex == i ? { ...tr, traitID: tId } : tr));
    setTraitsArr(updatedTr);
    filterAltVal(name);
  };

  return (
    <div>
      {/* minWidth: 200 */}
      <FormControl sx={{ mt: 1, width: '90%',background:'#ffffff' }}>
        <Select
          value={trait}
          onChange={dropDownHandler}
          displayEmpty
          inputProps={{ 'aria-label': 'Without label' }}>
          <MenuItem value="">
            <em>None</em>
          </MenuItem>
          {traits?.map((trait) => (
            <MenuItem key={trait?.name} value={trait?.name} name="traitID">
              {trait?.name}
            </MenuItem>
          ))}
        </Select>
        {/* <FormHelperText>Without label</FormHelperText> */}
      </FormControl>
    </div>
  );
}
