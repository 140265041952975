import { ethers } from "ethers";
import backgroundCriteria from "../data/backgroundCriteria.json"
import characterCriteria from "../data/characterCriteria.json"
import {cardValue,cardSuite,cardColor,cardType} from "../data/cards/cardTraits"

export const bigToDecimal = (num) => ethers.utils.formatEther(num);
export const decimalToBig = (num) => ethers.utils.parseEther(num);

export const getRealWorldAltValues = (sticker) => {
    let alt_value = parseInt(sticker['alternative_values']);
    let traitName = sticker['name'];
    let alternativeIds = [];
    for (let j = 0; j < 256; j++) {
        if ((alt_value & 1) == 1) {
            let arr=[]
            if (traitName==='CARD NUMBER') arr=cardValue;
            if (traitName==='Suit') arr=cardSuite;
            // if (traitName==='RED') arr=cardColor;
            // if (traitName==='Court Card') arr=cardType;
            alternativeIds.push({'name':traitName, properties:arr.find((a)=>{
                return a.id===j ? a.title : undefined
            })})
        }
        alt_value = alt_value >>> 1
    }
    return alternativeIds;
}

export const getRealWorldAltValuesSingleArry = (sticker) => {
    let titles =[];
    let alt_value = parseInt(sticker['alternative_values']);
    let traitName = sticker['name'];
    let alternativeIds = [];
    for (let j = 0; j < 256; j++) {
        if ((alt_value & 1) == 1) {
            let arr=[]
            if (traitName==='CARD NUMBER') arr=cardValue;
            if (traitName==='Suit') arr=cardSuite;
            if (traitName==='RED') arr=cardColor;
            if (traitName==='Court Card') arr=cardType;
            alternativeIds.push({'name':traitName, properties:arr.find((a)=>{
                if(a.id===j){
                    titles.push(a.title)
                    return a.title
                }else {
                    return undefined
                }
            })})
        }
        alt_value = alt_value >>> 1
    }
    return {alternativeIds,titles};
}
