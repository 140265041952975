import { useState, createContext, useContext,useReducer } from 'react';

const AuthContext = createContext({});

const AuthProvider = ({ children }) => {
  const [user, setUser] = useState({
    address: '',
    networkName: '',
    chainId: '',
    isConnected: null,
    isAdmin:null
  });



  return (
    <AuthContext.Provider value={{ user, setUser }}>{children}</AuthContext.Provider>
  );
};

const useAuthContext = () => useContext(AuthContext);

export { AuthContext, AuthProvider, useAuthContext };
