import * as React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

import CustomBtn from './CustomBtn';


function Row(props) {
  const { row } = props;
  const [open, setOpen] = React.useState(false);

  return (
    <React.Fragment>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' },backgroundColor: '#F56105' }}>
        <TableCell>
          <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell sx={tableSubHeading} component="th" scope="row">{row.stickerBookId}</TableCell>
        <TableCell sx={tableSubHeading}>{row.name}</TableCell>
        <TableCell sx={tableSubHeading}>{row.stickerCount}</TableCell>
        <TableCell sx={tableSubHeading}>{row.maxRedemptions}</TableCell>
        <TableCell sx={tableSubHeading}>{row.numberRedeemed}</TableCell>
        <TableCell sx={tableSubHeading}>{row.active ? 'Yes' : 'No'}</TableCell>
      </TableRow>
      <TableRow>
        {/* collapse table cell */}
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Typography variant="h6" gutterBottom component="div" sx={tableSubHeading}>Sticker Trait Details</Typography>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell sx={traitDetailsHeaderRow}>Position</TableCell>
                    <TableCell sx={traitDetailsHeaderRow}>Trait ID</TableCell>
                    <TableCell sx={traitDetailsHeaderRow}>Trait Name</TableCell>
                    <TableCell sx={traitDetailsHeaderRow} align="right">Real world traits</TableCell>
                  </TableRow>
                </TableHead>
                
                <TableBody>
                  {row?.stickers?.map((st) => (
                    <TableRow key={st.name} >
                      <TableCell sx={traitDetailsRow} component="th" scope="row">{st.index}</TableCell>
                      <TableCell sx={traitDetailsRow}>{st.traitID}</TableCell>
                      <TableCell sx={traitDetailsRow}>{st.name}</TableCell>
                      <TableCell sx={traitDetailsRow} align="right">{st.titles?.toString()}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}



const tableMainHeading ={ fontFamily: 'Rubik', fontSize: '18px',color: '#fff',lineHeight:'4rem',letterSpacing:"0.1em"}
const tableSubHeading ={ fontFamily: 'Rubik', fontSize: '18px',color: '#000'}
const traitDetailsHeaderRow ={ fontFamily: 'Rubik', fontSize: '18px',fontWeight:600,color: '#F56105'}
const traitDetailsRow ={ fontFamily: 'Rubik', fontSize: '14px',fontWeight:500,color: '#282824'}

export default function CollapsibleTable({ bookData,activateBook }) {
  const disableHandler =()=>{
    activateBook()
  }
  console.log("bookDatass => ",bookData[0]?.active)
  return (
    <>
      <TableContainer component={Paper}>
        <Table aria-label="collapsible table" sx={{backgroundColor:'#CACCB5'}}>
          <TableHead sx={{backgroundColor: '#000'}}>
            <TableRow>
              {/* Table main row */}
              <TableCell />
              <TableCell sx={tableMainHeading}>Book Id</TableCell>
              <TableCell sx={tableMainHeading}>Name</TableCell>
              <TableCell sx={tableMainHeading}>Sticker count </TableCell>
              <TableCell sx={tableMainHeading}>Max redemption</TableCell>
              <TableCell sx={tableMainHeading}>Max Redeemed</TableCell>
              <TableCell sx={tableMainHeading}>Active</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {bookData?.map((row) => (
              <Row key={row.stickerBookId} row={row} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Box sx={{ textAlign: 'center' }}>
        <CustomBtn 
          text={!bookData[0]?.active ?  "Activate Book" : "Deactivate Book"} 
          onClick={ ()=> !bookData[0]?.active ? activateBook(bookData[0]?.name,true):activateBook(bookData[0]?.name,false) } />
      </Box>
    </>
  );
}
