import React from 'react'
import { Grid,Typography } from '@mui/material'

const typo ={

}

const HeroSection = ({headingOne='default headingOne', headingTwo='default headingTwo',description="default desc"}) => {
  return (
    <Grid container justifyContent="center" direction="column" mt={5} mb={1}>
        {/* section one */}
        <Grid item md={12}>
          <Typography sx={{ textAlign: 'center', color: '#CACCB5',  lineHeight: '100px', fontFamily: 'Rubik',fontSize: '80px',fontWeight: 700, }}>
            {headingOne}
          </Typography>
        </Grid>
        <Grid item md={12}>
          <Typography sx={{ textAlign: 'center', color: '#CACCB5',  lineHeight: '100px', fontFamily: 'Rubik',fontSize: '80px',fontWeight: 700, }}>
            {headingTwo}
          </Typography>
        </Grid>
        <Grid item md={12}>
          <Typography sx={{ textAlign: 'center', color: '#CACCB5',  lineHeight: '50px', fontFamily: 'Rubik',fontSize: '24px',fontWeight: 300, }}>
            {description}
          </Typography>
        </Grid>
      </Grid>
  )
}

export default HeroSection