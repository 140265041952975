import React from "react";
import ClipLoader from "react-spinners/RiseLoader";
// import ClipLoader from "react-spinners/GridLoader";
// import ClipLoader from "react-spinners/BounceLoader";
// import ClipLoader from "react-spinners/PropagateLoader";
// import ClipLoader from "react-spinners/ScaleLoader";
// import ClipLoader from "react-spinners/HashLoader";
// import ClipLoader from "react-spinners/FadeLoader";
// import ClipLoader from "react-spinners/ClockLoader";
// import ClipLoader from "react-spinners/SquareLoader";
// import ClipLoader from "react-spinners/SyncLoader";


export function Loading() {
  return (
    <div
      style={{
        position: "fixed",
        zIndex: 2,
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        background: "rgba(255, 255, 255, 0.5)",
      }}
    >
      <div
        style={{
          position: "absolute",
          zIndex: 3,
          top: "50%",
          left: "53%",
        //   width: "100px",
        //   height: "50px",
          marginLeft: "-50px",
          marginTop: " -25px",
          textAlign: "center",
        }}
      >
        {/* <div className="spinner-border" role="status"> */}
        <ClipLoader color={"#152D2E"} loading={true} size={30} />
        {/* </div> */}
      </div>
    </div>
  );
}
