import React from 'react';
import { AuthProvider } from './auth';
import { Web3Provider } from '../blockchain/Web3ModalContext';

export const ContextProvider = ({ children }) => {
  return (
    <Web3Provider>
      <AuthProvider>
        {children}
      </AuthProvider>
    </Web3Provider>
  );
};
