//======================================== Routes ========================================
export const routes = {
  HOME: '/',
  DASHBOARD: '/dashboard',
  STICKER_BOOK:"sticker-book",
  BOOK: "/:bookName",
  SB_DETAILS: "/sb-details/:bookName",
  REWARDS:'rewards',
  SUBMISSIONS:"submissions",

  
};
Object.freeze(routes);


//======================================== Constants ========================================
export const constants = {

}


