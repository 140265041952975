import cardsAbi from './cards.json';
import registryAbi from './ECRegistryV2.json';
import stickerbookAbi from './galaxis_stickerbook_mark_two_transfer.json';

const contractAddresses = {
  stickerbook: process.env.REACT_APP_STICKER_BOOK_ADDRESS,
  registry: process.env.REACT_APP_REGISTRY_CONTRACT_ADDRESS,
  cards: process.env.REACT_APP_CARD_ADDRESS,
};
const contractAbis = {
  stickerbook: stickerbookAbi,
  registry: registryAbi,
  cards: cardsAbi,
};


export { contractAddresses,contractAbis };
