import React, { useState, useContext, useEffect, useTransition } from 'react';
import { useParams } from 'react-router-dom';
import {
  Grid,
  Typography,
  Box,
  ImageListItem,
  ImageListItemBar,
  IconButton,
  ImageList,
  Button,
} from '@mui/material';
import InfoIcon from '@mui/icons-material/InfoOutlined';
import HeroSection from '../components/HeroSection';
import { Web3ProviderContext } from '../blockchain/Web3ModalContext';
import { abis, addresses, makeContract } from '../blockchain/useContracts';
import {
  getTransformBookStickers,
  getTransformBookData,
  getTransformedBookSingleArray,
} from '../utils/transformObjects';
import { bigToDecimal, getRealWorldAltValues } from '../utils/helper';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { DragDropContext } from 'react-beautiful-dnd';
import Modal from '@mui/material/Modal';
import CustomBtn from '../components/CustomBtn';
import { Loading } from '../components/Loading';
import ListSubheader from '@mui/material/ListSubheader';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import DefaultNft from '../assets/images/defaultNft.png';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
//Table
import BasicTable from '../components/BasicTable';
import SbDetailsTable from '../components/SbDetailsTable';

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  // bgcolor: 'background.paper',
  border: '2px solid #152D2E',
  boxShadow: 24,
  borderRadius: '6px',
  p: 2,
  background: '#CACCB5',
};

const SbDetails = () => {
  const { connect } = useContext(Web3ProviderContext);
  const { bookName } = useParams();
  const [stickers, setStickers] = useState([]);
  const [bookData, setBookData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [userGrd, setUserGrd] = useState([]);
  const [openModal, setOpenModal] = React.useState(false);
  const [openNftModal, setOpenNftModal] = React.useState(false);
  const [modalData, setModalData] = React.useState([]);
  const [eligiblePositions, setEligiblePositions] = React.useState([]);

  useEffect(() => {
    getData();
  }, [bookName]);

  const getData = async () => {
    await getUserHexTokens();
    await getStickers();
    await getStickerBookData();
  };

  const handleOpen = () => setOpenModal(true);
  const handleClose = () => setOpenModal(false);
  const handleOpenNftModal = () => setOpenNftModal(true);
  const handleCloseNftModal = () => {
    setEligiblePositions([]);
    setOpenNftModal(false);
  };

  const getStickerBookData = async () => {
    let fullTraits = [];
    let transformBookStickers = [];
    const { signer } = await connect();
    const sb = makeContract(addresses.stickerBook, abis.stickerBook, signer);
    let fullBook = await sb.getStickerBookData(bookName);
    fullBook = getTransformBookData(fullBook);
    let { stickers } = fullBook;

    for (let i = 0; i < stickers.length; i++) {
      for (let j = 0; j < stickers[i].length; j++) {
        const element = stickers[i][j];
        // getRealWorldAltValues(stickers[i][j], i);
        transformBookStickers = getTransformedBookSingleArray(stickers[i][j], i);
        fullTraits.push(transformBookStickers);
      }
    }
    fullBook['stickers'] = fullTraits;
    console.log('fullBook =>', fullBook);
    console.log('fullTraits =>', fullTraits);
    setBookData(fullBook);

    setStickers(fullTraits);
  };

  const getStickers = async () => {
    let stickerArr = [];
    const { signer } = await connect();
    const sb = makeContract(addresses.stickerBook, abis.stickerBook, signer);
    const stickerPosition = 0;
    const bookId = await sb.stickerbooks(bookName);

    const bookById = await sb.stickerBookData(bookId);
    let book = getTransformBookData(bookById);
    setBookData(book);
    // console.log("book => ",book)

    const stickerCount = parseInt(bookById['stickerCount']);
    // console.log('bookData => ', bookData, parseInt(stickerCount));

    for (let j = 0; j < stickerCount; j++) {
      let sticker = await sb.stickers(bookId, j, stickerPosition);
      getRealWorldAltValues(sticker, j);
      stickerArr.push(getTransformBookStickers(sticker, j));
      // console.log("sticker old=>", stickerArr)
    }
    setStickers(stickerArr);
    setIsLoading(false);
  };

  const getUserHexTokens = async () => {
    setIsLoading(true);
    const { signer, address } = await connect();
    const hm = makeContract(addresses.cards, abis.cards, signer);
    let numTokens = await hm.balanceOf(address);
    // console.log('connected user address=>',address)
    numTokens = parseInt(numTokens);
    // console.log('connected user numner of tokens =>',numTokens)
    let userTokens = [];
    let promisses = [];
    // const idPromises = [];
    // const uriPromises = [];
    // const metaPromisses = [];
    // let uri = await hm.tokenURI(i);

    // getting the tokens id's of connected user
    for (let i = 0; i < numTokens; i++) {
      let promis = hm.tokenOfOwnerByIndex(address, i);
      promisses.push(promis);
    }
    let tokenIds = await Promise.all(promisses);
    tokenIds = tokenIds.map((t) => parseInt(t));

    // console.log('connected user tokenIds =>',tokenIds)

    promisses = [];

    // getting the tokens user againt then given tokens id's
    for (let j = 0; j < tokenIds?.length; j++) {
      let promis = hm.tokenURI(tokenIds[j]);
      promisses.push(promis);
    }
    const uriArr = await Promise.all(promisses);
    // console.log('connected user uri array =>',uriArr)

    promisses = [];

    // fetching the metadata for those tokens
    for (let j = 0; j < uriArr?.length; j++) {
      let promis = fetch(uriArr[j]);
      promisses.push(promis);
    }
    const res = await Promise.all(promisses);
    const meta = await Promise.all(res.map((r) => r.json()));
    // console.log('connected user metadata =>',meta)

    promisses = [];

    // retactoring data for UI
    if (tokenIds?.length === uriArr?.length && uriArr?.length === meta?.length) {
      for (let i = 0; i < tokenIds?.length; i++) {
        let obj = {};
        obj['tokenId'] = tokenIds[i];
        obj['uri'] = uriArr[i];
        obj['metaData'] = meta[i];
        userTokens.push(obj);
      }
    } else {
      console.log('tokens and there metada and there uri length does not match');
    }
    // console.log("connected users Tokens transformed data =>", userTokens);
    setUserGrd(userTokens);
    setIsLoading(false);
  };

  const stickerClickHangler = async (sticker, bookLength) => {
    handleOpen();
    let data = [];

    const { signer } = await connect();
    const sb = makeContract(addresses.stickerBook, abis.stickerBook, signer);
    // getting current connected user token id's array []
    const grdTokenIds = Object.values(userGrd.map((u) => u.tokenId));
    // checking that how many token's are eligible to put at clicked position
    const works = await sb.satisfies(bookName, sticker.index, grdTokenIds);
    const indexes = works.map((b, i) => (b ? i : -1)).filter((i) => i !== -1);
    const tokenIds = indexes.map((s) => grdTokenIds[s]);

    function checkIsTokenSelected(stk, uGrd) {
      const a = stk.every((g) => g.selectedTokenId !== uGrd.tokenId);
      return a;
    }

    if (tokenIds?.length) {
      for (let i = 0; i < tokenIds.length; i++) {
        for (let j = 0; j < userGrd.length; j++) {
          if (tokenIds[i] === userGrd[j].tokenId && checkIsTokenSelected(stickers, userGrd[j])) {
            data.push(userGrd[j]);
          }
        }
        data = data.map((d) => ({ ...d, stickerIndex: sticker.index, realWorldAltValues: [] }));
      }
    } else {
      data = [{ ...sticker }];
      // console.log("data used=>", sticker)
    }
    setModalData(data);

    let arr = [];
    for (let i = 0; i < bookLength; i++) {
      let obj = {};
      const boolArr = await sb.satisfies(bookName, i, grdTokenIds);
      const boolIndex = boolArr.map((ba, i) => (ba ? i : -1)).filter((i) => i !== -1);
      const idArr = boolIndex.map((s) => grdTokenIds[s]);
      if (idArr.length === 1) {
        // alert(`token id , ${idArr[0]} meet only one positon please set it first`)
        let tokenById = data.find((d) => d.tokenId === idArr[0]);
        obj['tokenById'] = tokenById;
        obj['index'] = i;
        arr.push(obj);
        // console.log('=>ja', tokenById);
      }
    }
    if (arr.length && arr.every((a) => stickers[a.index].image === '')) {
      selectTokens(arr);
    }
    //  console.log(i, 'works =>', works);
  };

  const selectTokens = (token) => {
    //token will be hextoken
    console.log('=>', token);
    let tempSt = stickers;

    if (Array.isArray(token) && token?.length) {
      for (let j = 0; j < token.length; j++) {
        // console.log("sitckerss",stickers)
        // console.log("token",token)
        if (stickers[token[j].index].image === '') {
          console.log('position', stickers[token[j].index].image);

          toast.info('Token that satisfy only position selected');
          tempSt = [...tempSt].map((st) => {
            if (st.index === token[j].index) {
              return {
                ...st,
                image: token[j].tokenById?.metaData?.image,
                selectedTokenId: token[j].tokenById?.tokenId,
              };
            }
            return { ...st };
          });
        }

        // console.log("tempStss =>",tempSt)
      }
    } else {
      tempSt = stickers.map((st) => {
        if (st.index === token.stickerIndex) {
          return { ...st, image: token.metaData.image, selectedTokenId: token.tokenId };
        }
        return { ...st };
      });
      handleCloseNftModal();
    }

    console.log('tempSt =>', tempSt);
    setStickers(tempSt);
    setModalData([]);
    handleClose();
  };

  const checkEligible = async (hexToken, traitTypes) => {
    console.log('traitTypes=>', hexToken);
    handleOpenNftModal();
    const { signer } = await connect();
    const sb = makeContract(addresses.stickerBook, abis.stickerBook, signer);
    let positions = await sb.eligible(bookName, hexToken.tokenId);
    // if position is [] then no any position eligible
    if (positions.length) {
      positions = positions.map((p) => parseInt(p));
      let stickerArr = [...stickers];
      let arr = [];
      for (let i = 0; i < positions.length; i++) {
        const position = positions[i];
        const found = stickerArr.find((st) => st.index === position);
        arr.push({ data: found, hexToken: { ...hexToken, stickerIndex: position } });
      }
      console.log('arr => ', arr);
      setEligiblePositions(arr);
      toast.info(`This token can be placed at positon ${positions.join('\n,')}`);
    } else {
      // alert('token id ' + hexToken.tokenId + ' does not meet any positions ', positions);
      toast.info(`This token does not meet any position ${positions.join('\n,')}`);
    }
  };

  const claimBook = async () => {
    try {
      setIsLoading(true);
      const { signer, address } = await connect();
      const sb = makeContract(addresses.stickerBook, abis.stickerBook, signer);

      // const grdTokenIds = Object.values(userGrd.map((u) => u.tokenId));
      const listStickers = Object.values(stickers.map((s) => s.index));
      let selectedTokenIds = Object.values(
        stickers.map((s) => s.selectedTokenId).filter((e) => e !== undefined),
      );

      const tokensToClaim = [];
      let result = [];
      console.log('listStickers', listStickers);
      console.log('selectedTokenId', selectedTokenIds);
      const checker = (arr) => arr.every(Boolean);

      // for (let j = 0; j < grdTokenIds.length; j++) {
      //   let positions = await sb.eligible(bookName, grdTokenIds[j]);
      //   positions = positions.map((p) => parseInt(p));
      //   if (positions.length && tokensToClaim.length < listStickers.length) {
      //     tokensToClaim.push(grdTokenIds[j]);
      //   }
      //   console.log("token id", grdTokenIds[j], "eligible for positions ", positions)
      // }
      // for (let i = 0; i < listStickers.length; i++) {
      //   for (let j = 0; j < tokensToClaim.length; j++) {
      //     result = await sb.satisfies(bookName, j + i, tokensToClaim)
      //   }
      // }

      for (let i = 0; i < listStickers.length; i++) {
        for (let j = 0; j < selectedTokenIds.length; j++) {
          result = await sb.satisfies(bookName, j + i, selectedTokenIds);
          console.log('result', result);
        }
      }

      if (selectedTokenIds.length === listStickers.length && checker(result)) {
        console.log('satisfies results', result);
        const grd = makeContract(addresses.cards, abis.cards, signer);
        let isApproved = await grd.isApprovedForAll(address, sb.address);
        console.log('isApproved || isApprovedForAll =>', isApproved);
        if (!isApproved) {
          let res = await grd.setApprovalForAll(sb.address, true);
          await res.wait();
        }

        console.log("selected tokenId's", selectedTokenIds);
        console.log('bookName to be claimed', bookName);
        let txClaim = await sb.claim(bookName, selectedTokenIds);
        await txClaim.wait(2);
        await resetBookData();
        await getUserHexTokens();
      } else {
        console.log('not satisfied or sticker book is not completed');
      }
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  const resetBookData = async () => {
    await getStickers();
  };

  const activateBook = async (name, status) => {
    try {
      setIsLoading(true)
      const { signer } = await connect();
      const sb = makeContract(addresses.stickerBook, abis.stickerBook, signer);
      let tx = await sb.activate(name, status);
      await tx.wait();
      await getData();

    } catch (error) {
      setIsLoading(false);

    }

  };

  return (
    <>
      {isLoading && <Loading />}

      <Box>
        <ToastContainer />
        <HeroSection
          headingOne={`Galaxis Stickerbook`}
          headingTwo=""
          description="You can see detailed view of Stickerbook"
        />

        <Box>
          <SbDetailsTable bookData={[bookData]} activateBook={activateBook} />
        </Box>
        {/* <ImageList cols={6} gap={10} sx={{ width: '100%', height: 500 }}>
          {
            userGrd?.map((u, index) => (
              <ImageListItem
                key={index}
                sx={{ cursor: 'pointer' }}
                onClick={() => checkEligible(u, u.metaData.attributes)}>
                <img
                  src={`${u?.metaData?.image}`}
                  alt={index}
                  loading="lazy"
                  style={{ objectFit: 'contain' }}
                />
                <ImageListItemBar
                  sx={{
                    '& .MuiImageListItemBar-title': {
                      fontSize: '20px',
                      fontFamily: 'Rubik',
                    },
                    '& .MuiImageListItemBar-subtitle': {
                      fontSize: '16px',
                      fontFamily: 'Rubik',
                      color: `${'#d9534f'}`,
                    },
                  }}
                  title={u.metaData.name}
                  subtitle={`token id ${u.tokenId}`}
                />
              </ImageListItem>
            ))
          }
        </ImageList> */}
      </Box>

      

      {/* <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        spacing={1}
        sx={{
          height: 'auto',
          background: '#CACCB9',
          width: '100%',
          margin: 'auto',
          borderRadius: '20px',
          padding: '10px',
        }}>
       

        <ImageList cols={6} gap={10} sx={{ width: '100%' }}>
          {stickers.map((sti, index) => (
            <ImageListItem key={index} sx={{ cursor: 'pointer' }}>
              <img
                // style={{objectFit:'contain'}}
                onClick={() => stickerClickHangler(sti, stickers.length)}
                src={sti?.image ? sti?.image : DefaultNft}
                // srcSet={`${'https://images.unsplash.com/photo-1551782450-a2132b4ba21d'}?w=248&fit=crop&auto=format&dpr=2 2x`}
                alt={index}
                loading="lazy"
              />
              <ImageListItemBar
                sx={{
                  '& .MuiImageListItemBar-title': { fontSize: '20px', fontFamily: 'Rubik' },
                  '& .MuiImageListItemBar-subtitle': {
                    fontSize: '16px',
                    fontFamily: 'Rubik',
                    color: `${'#ffffff'}`,
                  },
                }}
                title={sti.name}
                subtitle={`position ${index}`}
              />
            </ImageListItem>
          ))}
        </ImageList>
      </Grid> */}

      {/* <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} p={2}>
        <CustomBtn text="Reset" onClick={resetBookData} />
        <CustomBtn
          text="Claim"
          onClick={claimBook}
          disabled={bookData.maxRedemptions >= bookData.numberRedeemed ? false : true}
        />
      </Box> */}

    </>
  );
};

export default SbDetails;
