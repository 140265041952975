// lib
import React, { useState, useEffect, useContext, Fragment } from 'react';
import {
  Grid,
  Card,
  CardContent,
  TextField,
  Typography,
  Button,
  IconButton,
  Divider,
  Autocomplete,
  Checkbox,
} from '@mui/material';
import { Web3ProviderContext } from '../blockchain/Web3ModalContext';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
//constants
import { abis, addresses, makeContract } from '../blockchain/useContracts';
import { bigToDecimal } from '../utils/helper';
import backgroundCriteria from '../data/backgroundCriteria.json';
import characterCriteria from '../data/characterCriteria.json';
import legendaryCriteria from '../data/legendaryCriteria.json';
import perfectCriteria from '../data/perfectCriteria.json';
import { cardColor, cardType, cardSuite, cardValue } from '../data/cards/cardTraits'
//Components
import DropDown from './DropDown';
import TagSelector from './TagSelector';
import CustomBtn from './CustomBtn';
import { Loading } from './Loading';
import { Box } from '@mui/system';
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { toast, ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';


const sx = {
  traitContainer: {
    border: '3px solid #152D2E',
    borderRadius: 2,
    paddingBottom: '10px',
    marginTop: '20px',
    paddingBottom: '25px',
  },
  typo: { color: '#282824', fontSize: '18px', fontWeight: 500, fontFamily: 'Rubik' },
};

const AddMultipleSticker = ({ traits, getBooks }) => {
  const { connect } = useContext(Web3ProviderContext);
  const [bookName, setBookName] = useState('');
  const [maxRedemption, setMaxRedemption] = useState('');
  const [isClearBadge, setIsClearBadge] = useState(false);
  const [traitData, setTraitData] = useState([
    { traitID: '', alternative_values: [], traitName: '', tags: [] },
  ]);
  const [traitsArr, setTraitsArr] = useState([traitData]);
  const [isLoading, setIsLoading] = useState(false);
  const [pleaseUpdate, setPleaseUpdate] = useState(false);
  const [trait, setTrait] = React.useState('');

  useEffect(() => { }, [traitsArr, pleaseUpdate]);


  const inputHandler = (evt, index) => {
    const name = evt.target.name;
    const val = evt.target.value;
    const updatedTr = traitsArr.map((tr, i) => (index == i ? { ...tr, [name]: [val] } : tr));
    // setTraitData((pre) => ({ ...pre, [name]: [val] }));
    setTraitsArr(updatedTr);
    console.log('traitsArr', traitsArr);
  };

  const filterAltVal = (traitName, index, sIndex) => {
    // const updatedTr = traitsArr[index].map((tr, i) => (sIndex == i ? { ...tr, traitName: traitName } : tr));
    // setTraitsArr([updatedTr]);
  };

  const bookNameHandler = (evt) => {
    setBookName(evt.target.value);
  };
  const maxRedemptonHandler = (evt) => {
    setMaxRedemption(evt.target.value);
  };
  

  const add = async () => {
    try {
      setIsLoading(true);
      const { signer } = await connect();
      const sb = makeContract(addresses.stickerBook, abis.stickerBook, signer);
      if (traitsArr?.length > 1) {
        // alert('multiple critaria');
        console.log('=>', traitsArr);
        await sb.addSticker(bookName, traitsArr, { gasLimit: 1000000 });
      } else {
        // alert('single critaria');
        // await sb.addSticker(bookName, [traitsArr]);

        const obj = traitsArr[0];
        // const traitID = obj['traitID'];
        // const alternative_values = obj['alternative_values'];
        // await sb.addSticker("book1", [{traitID,alternative_values}]);
        console.log('=>', traitsArr);

        await sb.addSticker(bookName, [traitsArr[0]], { gasLimit: 1000000 });
      }
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  const addRow = (fIndex) => {
    let temp = traitsArr.map((ta, i) => {
      if (fIndex === i) {
        return [...ta, ...traitData];
      }
      return ta;
    });

    setTraitsArr([...temp]);
  };
  const addAnotherSticker = () => {
    setTraitsArr([...traitsArr, traitData]);
  };
  const removeTraitRow = (fIndex) => {
    const filteredTr = [...traitsArr];
    filteredTr.splice(fIndex, 1); //remove one item from that index
    console.log('filteredTr', filteredTr);
    setTraitsArr(filteredTr);
  };
  
  const createBookWithStickers = async () => {
    try {
      setIsLoading(true);
      const { signer } = await connect();
      let tempArr = [...traitsArr];
      console.log("tempArr=>", tempArr[0].length)
      const sb = makeContract(addresses.stickerBook, abis.stickerBook, signer);
      if (bookName === '') toast.info("Write sticker bookname");
      if (maxRedemption === '') toast.info("Write max redumption");
      if (!tempArr[0].length > 1) toast.info("Select at least two traits");
      if (bookName !== '' && maxRedemption !== '' && tempArr[0].length > 1) {
        let bookId = await sb.stickerbooks(bookName);
        bookId = parseInt(bookId);
        console.log('bookId=>', bookId);
        if (bookId === 0 && bookName !== '') {
          let stix = tempArr.map((singleArr) =>
            singleArr.filter(
              (obj) => delete obj.tags && delete obj.traitName && obj.traitID.toString(),
            ),
          );
          // stix = stix.map((st) => st.filter((s) => (s.traitID).toString()))
          let struct = {};
          struct['name'] = bookName;
          struct['maxRedemptions'] = parseInt(maxRedemption);
          struct['clearBadge'] = isClearBadge;
          struct['traitToClear'] = isClearBadge ===true ? traits.find((t)=>t.name==="BADGE").traitId : 0;
          struct['stix'] = stix;
          console.log('struct =>', struct);
          const tx = await sb.addFullStickerBook(struct);
          await tx.wait();
          console.log(tx.data);
          setTraitsArr([traitData]);
          setBookName('');
          setMaxRedemption('');
          setIsClearBadge(false)
          await getBooks()

        } else if (bookId > 0) {
          toast.info('Stickerbook name already exist...!');
        } else {
          toast.info('something wrong...!');
        }
      }
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
      setTraitsArr([traitData]);
    }
  };

  const dropDownHandler = (event, rowIndex, sIndex) => {
    let tags = [];
    switch (event.target.value) {
      case 'CARD NUMBER':
        tags = cardValue;
        break;
      case 'Suit':
        tags = cardSuite;
        break;
      case 'Court Card':
        tags = cardType;
        break;
      case 'RED':
        tags = cardColor;
        break;
      default:
        tags = [];
    }

    let tId = traits.filter((trait) => trait.name === event.target.value);
    let temp = traitsArr;
    let updatedTr;
    if (tId?.length) tId = tId[0]['traitId'];
    if (tId === 2 || tId === 3) {
      updatedTr = traitsArr[rowIndex].map((tr, i) =>
        sIndex == i ? { ...tr, traitID: tId, traitName: event.target.value, tags: tags, alternative_values: [0] } : tr,
      );
    } else {
      updatedTr = traitsArr[rowIndex].map((tr, i) =>
        sIndex == i ? { ...tr, traitID: tId, traitName: event.target.value, tags: tags } : tr,
      );
    }

    temp[rowIndex] = updatedTr;
    setTraitsArr(temp);
    filterAltVal(event.target.value, rowIndex, sIndex);
    setPleaseUpdate(!pleaseUpdate);
  };

  const tagHandler = (criteria, fIndex, sIndex, isCkecked) => {
    let alternative_val = [];
    let temp = traitsArr;

    if (isCkecked === false || isCkecked === true && isCkecked !== null) {
      let abc
      if (isCkecked) {
        abc = traitsArr[fIndex].map((tr, i) =>
          sIndex == i ? { ...tr, alternative_values: [1] } : tr)
        temp[fIndex] = abc;
      } else if (!isCkecked) {
        abc = traitsArr[fIndex].map((tr, i) =>
          sIndex == i ? { ...tr, alternative_values: [0] } : tr)
        temp[fIndex] = abc;
      }

    } else {
      criteria.forEach((element) => alternative_val.push(element.id));
      const updatedTr = traitsArr[fIndex].map((tr, i) =>
        sIndex == i ? { ...tr, alternative_values: alternative_val } : tr,
      );
      temp[fIndex] = updatedTr;

    }
    setTraitsArr(temp);
  };
  const clearBadgeHandler =(isChecked)=>{
    setIsClearBadge(isChecked)
  }

  return (
    <>
      {isLoading && <Loading />}
      <Card sx={{ backgroundColor: '#CACCB5', padding: '20px 30px 0px 30px', borderRadius: '10px' }}>
        <CardContent>
          <Grid container spacing={3} justifyContent="center">
            <Grid item md={7}>
              <Typography sx={{ color: '#282824', fontSize: '18px', fontWeight: 500, fontFamily: 'Rubik' }}>
                Sticker book name
              </Typography>
              <TextField
                sx={{ backgroundColor: 'white', borderRadius: '10px' }}
                placeholder="write sticker book unique name"
                variant="outlined"
                fullWidth
                onChange={bookNameHandler}
                name="bookName"
                value={bookName}
              />
            </Grid>

            {/* maxRedemptions */}
            <Grid item md={3}>
              <Typography sx={{ color: '#282824', fontSize: '18px', fontWeight: 500, fontFamily: 'Rubik' }}>
                Max Redemptions
              </Typography>
              <TextField
                sx={{ backgroundColor: 'white', borderRadius: '10px' }}
                placeholder="write number not less then 1"
                variant="outlined"
                fullWidth
                type='number'
                onChange={maxRedemptonHandler}
                name="maxRedempton"
                value={maxRedemption}
              />
            </Grid>

            

            <Grid item md={2}>
              <Typography sx={{ color: '#282824', fontSize: '18px', fontWeight: 500, fontFamily: 'Rubik' }}>
              Clear Badge
              </Typography>
              <Checkbox size="large" checked={isClearBadge} onChange={(e) => clearBadgeHandler(e.target.checked)}/>
            </Grid>

          </Grid>

          <Divider sx={{ margin: '20px 0px' }} />

          <Grid
            container
            spacing={3}
            justifyContent="center"
            alignItems="center"
            direction="column">
            {traitsArr?.map((tra, index) => (
              <Grid item container direction="row" sx={sx.traitContainer}>
                {tra?.map((t, i) => (
                  <Fragment key={i}>
                    <Grid item md={4}>
                      <Typography sx={sx.typo}>Select Trait</Typography>
                      <FormControl
                        sx={{ mt: 1, width: '90%', background: '#ffffff', marginTop: 0 }}>
                        <Select
                          value={t?.traitName !== '' ? t?.traitName : trait}
                          onChange={(e) => dropDownHandler(e, index, i)}
                          displayEmpty
                          inputProps={{ 'aria-label': 'Without label' }}>
                          <MenuItem value="">
                            <em>None</em>
                          </MenuItem>
                          {traits?.map((trait) => (
                            <MenuItem key={trait?.name} value={trait?.name} name="traitID">
                              {trait?.name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>

                    <Grid item md={6}>
                      <Typography sx={sx.typo}>Alternative values</Typography>
                      {console.log('t', i, t)}

                      {(t?.traitName === 'Court Card' || t?.traitName === 'RED') && (
                        <Checkbox
                          size="large"
                          onChange={(e) => tagHandler(t, index, i, e.target.checked)}
                        />
                      )}

                      {t?.traitName != 'Court Card' && t.traitName != 'RED' && (
                        <Autocomplete
                          multiple={true}
                          limitTags={4}
                          id="multiple-limit-tagss"
                          options={t?.tags}
                          getOptionLabel={(option) => option?.title}
                          defaultValue={[]}
                          onChange={(e, newVal) => tagHandler(newVal, index, i, null)}
                          renderInput={(params) => (
                            <TextField {...params} placeholder="search available tags" />
                          )}
                          sx={{ width: '100%', background: '#ffffff', borderRadius: '4px' }}
                        />
                      )}
                    </Grid>

                    <Grid item md={1}>
                      {tra?.length - 1 === i && tra?.length < 4 && (
                        <IconButton
                          sx={{
                            marginTop: '20px',
                            textAlign: 'center',
                            paddingTop: '15px',
                            paddingLeft: '20px',
                          }}
                          onClick={() => addRow(index)}>
                          <AddBoxOutlinedIcon fontSize="large" sx={{ color: '#282824' }} />
                        </IconButton>
                      )}
                    </Grid>
                    <Grid item md={1}>
                      {tra?.length - 1 === i && tra?.length > 1 && (
                        <IconButton
                          sx={{ marginTop: '20px', textAlign: 'center', paddingTop: '15px' }}
                          onClick={() => removeTraitRow(index, i)}>
                          <DeleteForeverOutlinedIcon fontSize="large" sx={{ color: '#282824' }} />
                        </IconButton>
                      )}
                    </Grid>
                  </Fragment>
                ))}
              </Grid>
            ))}
          </Grid>

          {/* <Grid container spacing={3} justifyContent="center" alignItems="center"
                    // sx={{ border: '2px solid #152D2E', borderRadius: 2 }}
>
                        {traitsArr?.map((d, index) => (
                            <Fragment key={index}>
                                {console.log(d)}
                                <Grid item md={4}>
                                    <Typography
                                        sx={{
                                            color: '#282824',
                                            fontSize: '18px',
                                            fontWeight: 500,
                                            fontFamily: 'Rubik',
                                        }}>
                                        Select Trait
                                    </Typography>
                                    <DropDown
                                        traits={traits}
                                        setTraitsArr={setTraitsArr}
                                        traitsArr={traitsArr}
                                        rowIndex={index}
                                        filterAltVal={filterAltVal}
                                    />
                                </Grid>

                                <Grid item md={5}>
                                    <Typography
                                        sx={{ color: '#282824', fontSize: '18px', fontWeight: 500, fontFamily: 'Rubik' }}>
                                        Alternative values
                                    </Typography>
                                    <TagSelector setTraitsArr={setTraitsArr} traitsArr={traitsArr} rowIndex={index} tagOptions={criteria} />
                                </Grid>
                                <Grid item md={2}>
                                    {traitsArr?.length > 1 && (
                                        <IconButton
                                            sx={{ marginTop: '20px', textAlign: 'center' }}
                                            onClick={() => removeTraitRow(index)}>
                                            <DeleteForeverOutlinedIcon fontSize="large" sx={{ color: '#282824' }} />
                                        </IconButton>
                                    )}
                                </Grid>
                                <Grid item md={12} sx={{ textAlign: 'center' }}>
                                    <CustomBtn text='Add Row' onClick={addRow} />
                                </Grid>

                            </Fragment>
                        ))}


                    </Grid> */}
          <Box sx={{ textAlign: 'center' }}>
            <CustomBtn text="Add Another Sticker" onClick={addAnotherSticker} />
            <CustomBtn text="Create to the book" onClick={createBookWithStickers} />
          </Box>
        </CardContent>
      </Card>
      <ToastContainer />
    </>
  );
};
export default AddMultipleSticker;
