import { Icon } from '@mui/material';

import { routes } from '../../utils/global.config';
import { MoveToInbox } from '@mui/icons-material';
import DashboardIcon from '@mui/icons-material/Dashboard';
import ArticleIcon from '@mui/icons-material/Article';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import SettingsIcon from '@mui/icons-material/Settings';
import BookmarkAddedIcon from '@mui/icons-material/BookmarkAdded';
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import LogoutIcon from '@mui/icons-material/Logout';
import HomeIcon from '@mui/icons-material/Home';

// ----------------------------------------------------------------------

// const getIcon = (name) => <Icon icon={name} width={22} height={22} />;

const sidebarDetails = [
  {
    title: 'Home',
    path: routes.HOME,
    Icon: <HomeIcon />,
    nested: null,
  },
  {
    title: 'Sticker Book',
    path: routes.STICKER_BOOK,
    Icon: <BookmarkAddedIcon />,
    nested: null,
  },
  {
    title: 'Dashboard',
    path: '/dashboard',
    Icon: <DashboardIcon />,
    nested: null,
  },
  // {
  //   title: 'Main Contracts',
  //   path: '/contracts',
  //   Icon: (<ArticleIcon/>),
  //   nested: null,
  // },
  // {
  //   title: 'Trait Wizart',
  //   path: '',
  //   Icon: (<AutoFixHighIcon/>),
  //   nested: [
  //     {
  //       title: 'Trait Types',
  //       path: '/',
  //     },
  //     {
  //       title: 'Trait List',
  //       path: '/',
  //     },
  //     {
  //       title: 'Add New Trait',
  //       path: '/',
  //     },
  //     {
  //       title: 'Drop Trait on NFTs',
  //       path: '/',
  //     },

  //   ],
  // },
  // {
  //   title: 'Trait Administration',
  //   path: '/administration',
  //   Icon: (<AdminPanelSettingsIcon/>),
  //   nested: [
  //     {
  //       title: 'Appointment',
  //       path: '/',
  //     },
  //     {
  //       title: 'Autograph',
  //       path: '/',
  //     },
  //     {
  //       title: 'Redeem Own',
  //       path: '/',
  //     },
  //   ],
  // },
  // {
  //   title: 'Configuration',
  //   path: '/configuration',
  //   Icon: (<SettingsIcon/>),
  //   nested: null
  // },
];

export { sidebarDetails };
