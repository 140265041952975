// Lib imports
import React, { useEffect } from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import Collapse from '@mui/material/Collapse';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import StarBorder from '@mui/icons-material/StarBorder';
import LogoutIcon from '@mui/icons-material/Logout';
import LoginIcon from '@mui/icons-material/Login';

import { ReactComponent as GalaxisIcon } from './assets/svg/galaxis.svg';
import { sidebarDetails } from './components/sidePenal/sidebarConfig';

import { Container } from '@mui/material';
import { BrowserRouter, Route, Routes, Link, useNavigate } from 'react-router-dom';
//Components
import Header from './components/header';
import SidePenal from './components/sidePenal/';
import Home from './pages/Home';
import Dashboard from './pages/Dashboard';
import Rewards from './pages/Rewards';
import StickerBook from './pages/StickerBook';
import Submissions from './pages/Submissions';
import StickersView from './pages/StickersView';
import SbDetails from './pages/SbDetails';
import { useAuthContext } from './context/auth';
import { useWeb3ModalContext,Web3Provider } from './blockchain/Web3ModalContext';
// utils
import { routes } from './utils/global.config';
import { abis, addresses, makeContract } from './blockchain/useContracts';
const drawerWidth = 250;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);

function App() {
  const [open, setOpen] = React.useState(true);
  const [collapsState, setCollapsState] = React.useState({});
  const { user, setUser, } = useAuthContext();
  const { handleLogin, isConnected, disconnect,setIsConnected } = useWeb3ModalContext();
  console.log("current user=>",user);
  console.log("isConnected",isConnected);

  const handleDrawerClose = () => {
    setOpen(false);
  };
  const handleClick = (t) => {
    if (collapsState[t] === true) {
      setCollapsState({ ...collapsState, [t]: !collapsState[t] });
    } else {
      setCollapsState({ ...collapsState, [t]: true });
    }
    console.log(t, collapsState[t]);
  };
  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const loginHandler = async () => {
    if (isConnected) {
      disconnect();
      console.log("user in disconnect",user)
      setUser(prevState => {
        // Object.assign would also work
        return {...prevState, 
          address:'',
          provider:'',
          signer:'',
          isConnected:false,
          networkName:'',
          chainId:''
        };
      });


    } else if (!isConnected) {
      const { provider, signer, address,network} = await handleLogin();
      const sb = makeContract(addresses.stickerBook,abis.stickerBook, signer);
      let isAdmin = sb.allowed(address);


      console.log("address=>",address)
      setUser(prevState => {
        // Object.assign would also work
        return {...prevState, 
          address,
          provider,
          signer,
          isConnected:true,
          networkName:network.name,
          chainId:network.chainId,
          isAdmin:isAdmin
        };
      });

    } else {
      console.log('look loginHandler');
    }
  };

  return (
    <BrowserRouter>
      {/* <Header /> */}
      {/* <SidePenal/> */}
      <Box sx={{ display: 'flex' }}>
        {/* <CssBaseline /> */}
        <AppBar position="fixed" open={open} sx={{ background: '#CACCB5' }}>
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              sx={{ marginRight: 5, ...(open && { display: 'none' }) }}>
              <MenuIcon />
            </IconButton>
            <IconButton sx={{ width: 180, height: 48 }} component={Link} to={routes.HOME}>
              <GalaxisIcon />
            </IconButton>
            <Typography variant="h6" noWrap component="div">
            {user.address}
          </Typography>
          </Toolbar>
        </AppBar>
        <Drawer
          variant="permanent"
          open={open}
          sx={{ '& .MuiDrawer-paper': { background: '#CACCB5' } }}>
          <DrawerHeader>
            <Typography sx={{textAlign:'left'}}>{user.isAdmin ? 'Admin' :''}</Typography>

            {/* <IconButton onClick={handleDrawerClose}>{open && <ChevronLeftIcon />}</IconButton> */}
          </DrawerHeader>
          <Divider />
          <List>
            {sidebarDetails?.map((item, index) => (
              <ListItem
                key={index}
                disablePadding
                sx={{
                  display: 'block',
                  '&:hover': {
                    color: '#F56105',
                    backgroundColor: '#152D2E',
                  },
                }}>
                <ListItemButton
                  component={Link}
                  to={item.path}
                  onClick={() => handleClick(item.title)}
                  sx={{ minHeight: 48, justifyContent: open ? 'initial' : 'center', px: 2.5 }}>
                  <ListItemIcon
                    sx={{ minWidth: 0, mr: open ? 2 : 'auto', justifyContent: 'center' }}>
                    {item.Icon}
                  </ListItemIcon>
                  <ListItemText
                    primary={item.title}
                    sx={{
                      opacity: open ? 1 : 0,
                      '& .MuiTypography-root': {
                        fontFamily: 'Rubik',
                      },
                    }}
                  />
                  {item.nested && open && (
                    <>{collapsState[item.title] ? <ExpandLess /> : <ExpandMore />}</>
                  )}
                </ListItemButton>
                {item.nested &&
                  item.nested.map((nest, index) => (
                    <Collapse
                      in={collapsState[item.title]}
                      timeout="auto"
                      unmountOnExit
                      key={index}>
                      <List component="div" disablePadding>
                        <ListItemButton sx={{ pl: 5 }}>
                          {/* <ListItemIcon>
                            <StarBorder />
                          </ListItemIcon> */}
                          <ListItemText
                            primary={nest.title}
                            sx={{
                              '& .MuiTypography-root': {
                                fontFamily: 'Rubik',
                              },
                            }}
                          />
                        </ListItemButton>
                      </List>
                    </Collapse>
                  ))}
              </ListItem>
            ))}
          </List>

          <Divider />
          <List>
            <ListItem disablePadding sx={{ display: 'block' }}>
              <ListItemButton
                onClick={loginHandler}
                sx={{ minHeight: 48, justifyContent: open ? 'initial' : 'center', px: 2.5 }}>
                <ListItemIcon>{isConnected ? <LogoutIcon /> : <LoginIcon />}</ListItemIcon>
                <ListItemText
                  primary={isConnected ? 'Disconnect' : 'Connect'}
                  sx={{ opacity: open ? 1 : 0 }}
                />
              </ListItemButton>
            </ListItem>
          </List>
        </Drawer>
        <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
          <DrawerHeader />
          <Container maxWidth="lg">
            <Routes>
              <Route path={routes.HOME} element={<Home />} />
              <Route path={routes.STICKER_BOOK} element={<StickerBook />} />
              <Route path={routes.DASHBOARD} element={<Dashboard />} />
              <Route path={routes.BOOK} element={<StickersView />} />
              <Route path={routes.SB_DETAILS} element={<SbDetails />} />
              <Route path={routes.SUBMISSIONS} element={<Submissions />} />
              <Route path={routes.REWARDS} element={<Rewards />} />
            </Routes>
          </Container>
        </Box>
      </Box>
    </BrowserRouter>
  );
}

export default App;
