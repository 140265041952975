//     const CARD_NUMBER_TRAIT = 0
//     const SUIT_TRAIT  = 1
//     const COURT_CARD    = 2
//     const IS_RED  = 3

export const cardValue = [
  { id: 1, traidId: 0, title: '1', name: 'Value' },
  { id: 2, traidId: 0, title: '2', name: 'Value' },
  { id: 3, traidId: 0, title: '3', name: 'Value' },
  { id: 4, traidId: 0, title: '4', name: 'Value' },
  { id: 5, traidId: 0, title: '5', name: 'Value' },
  { id: 6, traidId: 0, title: '6', name: 'Value' },
  { id: 7, traidId: 0, title: '7', name: 'Value' },
  { id: 8, traidId: 0, title: '8', name: 'Value' },
  { id: 9, traidId: 0, title: '9', name: 'Value' },
  { id: 10, traidId: 0, title: '10', name: 'Value' },
  { id: 11, traidId: 0, title: '11', name: 'Value' },
  { id: 12, traidId: 0, title: '12', name: 'Value' },
  { id: 13, traidId: 0, title: '13', name: 'Value' },
];

export const cardSuite = [
  { id: 1, traidId: 1, title: 'Club', name: 'Suite' },
  { id: 2, traidId: 1, title: 'Heart', name: 'Suite' },
  { id: 3, traidId: 1, title: 'Spades', name: 'Suite' },
  { id: 4, traidId: 1, title: 'Diamonds', name: 'Suite' },
];

export const cardType = [
  { id: 0, traidId: 2, title: 'Number', name: 'Card Type', label: 'No' },
  { id: 1, traidId: 2, title: 'Court Card', name: 'Card Type', label: 'Yes' },
];

export const cardColor = [
  { id: 0, traidId: 3, title: 'RED', name: 'Card Colour', label: 'No' },
  { id: 1, traidId: 3, title: 'BLACK', name: 'Card Colour', label: 'Yes' },
];
