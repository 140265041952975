import { ethers } from 'ethers';
//=======================================================
import { contractAddresses,contractAbis } from './contracts';

const abis = {
  registry: new ethers.utils.Interface(contractAbis.registry),
  stickerBook: new ethers.utils.Interface(contractAbis.stickerbook),
  cards: new ethers.utils.Interface(contractAbis.cards),
};

const addresses = {
  registry: contractAddresses.registry,
  stickerBook: contractAddresses.stickerbook,
  cards: contractAddresses.cards,
};

const makeContract = (address, abi, signer) => {
  if (!address || !abi || !signer) {
    throw new Error('Insuficient arguments');
  }
  return new ethers.Contract(address, abi, signer);
};

const asyncContractCall = async (contract, method, args, transformFunc) => {
  try {
    console.log(contract, method);
    return await contract[method](...args);
  } catch (error) {
    console.log(error);
  }
};

export { abis, addresses, makeContract, asyncContractCall };
