import React from 'react';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const CustomToaster = ({ status }) => {
  (function () {
    toast.info('i am iffi function');
  })();
  return <ToastContainer />;
};
